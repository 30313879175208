<template>
  <div>
    <div class="paytop">
      <div class="list">
        <img src="~img/client/weixinzhifu@2x.png" alt="" />
        <img src="~img/client/weixinzhifu copy@2x.png" alt="" />
        <img src="~img/client/yunshanfu@2x.png" alt="" />
      </div>
      <p>您可以嘗試使用微信或支付寶支付哦~</p>
      <a
        class="goAppstore"
        href="https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/editAddress?mt=8"
        >去App Store設置</a
      >
    </div>
    <div class="line"></div>
    <div class="payhelp">
      <h3>充值幫助</h3>
      <p>
        如何設置我的支付方式？<br />
        以微信支付爲例，微信、支付寶等按照頁面提示操作即可
      </p>
      <ul>
        <li>
          <div class="clearfix">
            <span class="circle fl"> </span>
            <div class="fl right">
              點擊上方按鈕<a class="goAppstore" href="#">去App Store設置</a>
              跳轉至App Store進行設置，在賬戶設置頁，選擇“微信支付WeChat
              pay”，點擊“前往微信驗證”；
            </div>
          </div>
          <img src="~img/client/zhifu.png" alt="" />
        </li>
        <li>
          <div class="clearfix">
            <span class="circle fl"> </span>
            <div class="fl right">
              在跳轉頁面點：“打開”
            </div>
          </div>
        </li>
        <li>
          <div class="clearfix">
            <span class="circle fl"> </span>
            <div class="fl right">
              開通免密支付點“下一步”
            </div>
          </div>
          <img class="paysu" src="~img/client/paysu.png" alt="" />
        </li>
        <li>
          <div class="clearfix">
            <span class="circle fl"> </span>
            <div class="fl right">
              輸入密碼，或識別人臉，驗證身份
            </div>
          </div>
        </li>
        <li>
          <div class="clearfix">
            <span class="circle fl"> </span>
            <div class="fl right">
              驗證成功後，前往App Store即可自動完成綁定
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.paytop
    padding 40px 0
    text-align center
    .list
        margin 0 211px 20px
        img
            width 66px
            height 66px
            &:nth-child(2)
                margin 0 60px
    p
        font-size:28px;
        color:rgba(85,85,85,1);
        line-height:40px;
        margin-bottom 20px
    .goAppstore
        width:332px;
        height:56px;
        background:linear-gradient(135deg,rgba(122,209,255,1) 0%,rgba(29,133,241,1) 100%);
        box-shadow:0px 1px 8px 0px rgba(118,206,254,1);
        border-radius:28px;
        text-align center
        line-height 56px
        display inline-block
        color #fff
        font-size:28px;
.payhelp
    padding 31px 56px 74px 48px
    h3
        font-size:34px;
        font-weight 600
        line-height 48px
        color #333
        margin-bottom 13px
    p
        font-size:24px;
        color:rgba(153,153,153,1);
        line-height 40px
    ul
        margin-top 24px
        li
            margin-bottom 15px
            span
                display  inline-block
                width:12px;
                height:12px;
                background:rgba(168,199,235,1);
                border-radius 50%
                margin-top 18px
            .right
                width 614px
                color #555
                font-size 26px
                line-height 50px
                margin-left 20px
            a
                width:214px;
                height:40px;
                font-size:18px;
                background:linear-gradient(135deg,rgba(122,209,255,1) 0%,rgba(29,133,241,1) 100%);
                box-shadow:0px 1px 8px 0px rgba(118,206,254,1);
                border-radius:28px;
                text-align center
                line-height 45px
                display inline-block
                color #fff
                margin 0 30px
            img
                width 442px
                margin-left 100px
                margin-top 27px
</style>
